<template>
  <div>
    <v-item-group
      class="d-flex flex-wrap mt-3"
      style="gap: 10px"
      v-model="activeItem"
      active-class="primary--text"
    >
      <v-item
        v-slot="{ active, toggle }"
        v-for="(sol, index) in solicitudesSorted"
        :key="index"
        :value="sol.CodigoContrato"
      >
        <v-card
          :loading="!sol.IdSolicitud"
          @click.stop="toggle"
          :outlined="active"
          width="200"
          height="200"
          class="pa-2 d-flex flex-column justify-space-between"
        >
          <h3 class="text-center">{{ sol.CodigoContrato }}</h3>
          <div class="d-flex flex-column align-center">
            <v-chip
              v-if="sol.fecha && sol.IdSolicitud"
              small
              label
              outlined
              class="text-center text-caption"
              >{{ sol.fecha }}</v-chip
            >
          </div>
          <p class="text-center text-caption text--secondary mb-0 pa-0">
            {{
              sol.RazonSocial ||
                [sol.Nombre, sol.Apellido1, sol.Apellido2]
                  .filter((x) => !!x)
                  .join(" ")
            }}
          </p>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="sol.IdSolicitudPropia && !sol.IdSolicitud"
              @click.stop="anularSolicitud(sol)"
              text
              small
              color="error"
              >Eliminar solicitud</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-item>
      <v-item>
        <v-dialog scrollable max-width="700">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-on="on"
              v-bind="attrs"
              outlined
              style="border-width: 3px; border-style: dashed; background: transparent"
              width="200"
              height="200"
              class="pa-2 d-flex flex-column justify-center align-center"
            >
              <v-btn x-large icon>
                <v-icon x-large>mdi-plus</v-icon>
              </v-btn>
            </v-card>
          </template>
          <NuevaSolicitudReenganche @reload="$emit('reload')" />
        </v-dialog>
      </v-item>
    </v-item-group>

    <ConfirmDialog ref="confirm" />

  </div>
</template>

<script>
import { parseDate } from "@/utils/index.js";
export default {
  props: {
    solicitudes: Array,
  },
  components: {
    NuevaSolicitudReenganche: () =>
      import("../components/NuevaSolicitudReenganche.vue"),
          ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  methods: {
    parseDate,
    async anularSolicitud(sol) {

      const r = await this.$refs.confirm.open(`¿Estás seguro de que quieres solicitar que continue cortado?`, ``, { color: 'error', confirmColor: 'error', width: 600 })
      if (!r) return;

      axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/solicitudesCorte.php`,
        method: "DELETE",
        data: {
          token: this.$store.getters.getJwtEmpresa,
          IdSolicitudPropia: sol.IdSolicitudPropia,
        },
      })
        .then((res) => {
          this.$root.$emit(
            "snack",
            "Se ha anulado la solicitud de reenganche"
          );
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit(
            "snack",
            "No se ha podido anular la solicitud de reenganche"
          );
        });
    },
  },
  data() {
    return {
      activeItem: null,
    };
  },
  computed: {
    solicitudesSorted() {
      return this.solicitudes.sort(
        (a, b) =>
          parseDate(a.FechaPrevistaActivacion, true) -
          parseDate(b.FechaPrevistaActivacion, true)
      );
    },
  },
  watch: {
    activeItem(n) {
      this.$emit("filter", n || null);
    },
  },
};
</script>
